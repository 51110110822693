import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/armeria/armeria/site/src/layouts/docs.tsx";
export const pageTitle = "Calling a Thrift service";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const Tip = makeShortcode("Tip");
const layoutProps = {
  pageTitle,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "calling-a-thrift-service",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#calling-a-thrift-service",
        "aria-label": "calling a thrift service permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Calling a Thrift service`}</h1>
    <h6 {...{
      "className": "inlinePageToc",
      "role": "navigation"
    }}>{`Table of contents`}</h6>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#see-also"
        }}>{`See also`}</a></li>
    </ul>
    <Tip mdxType="Tip">
      <p>{`Visit `}<a parentName="p" {...{
          "href": "https://github.com/line/armeria-examples"
        }}>{`armeria-examples`}</a>{` to find a fully working example.`}</p>
    </Tip>
    <p>{`Let's assume we have the following Thrift IDL, served at `}<inlineCode parentName="p">{`http://127.0.0.1:8080/hello`}</inlineCode>{`, just like what we
used in `}<a parentName="p" {...{
        "href": "/docs/server-thrift"
      }}>{`Running a Thrift service`}</a>{`:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-protobuf"
      }}>{`namespace java com.example.thrift.hello

service HelloService {
    string hello(1:string name)
}
`}</code></pre>
    <p>{`Making a call starts from creating a client:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-java"
      }}>{`import com.linecorp.armeria.client.thrift.ThriftClients;

// Use the tbinary format by default.
HelloService.Iface helloService = ThriftClients.newClient(
    "http://127.0.0.1:8080/hello",
    HelloService.Iface.class); // or AsyncIface.class

String greeting = helloService.hello("Armerian World");
assert greeting.equals("Hello, Armerian World!");
`}</code></pre>
    <p>{`Note that if a serialization format is unspecified, `}<a parentName="p" {...{
        "href": "type://ThriftSerializationFormats#BINARY:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/common/thrift/ThriftSerializationFormats.html#BINARY"
      }}>{`type://ThriftSerializationFormats#BINARY`}</a>{` is used by
default. You can also specify one of `}<a parentName="p" {...{
        "href": "type://ThriftSerializationFormats#BINARY:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/common/thrift/ThriftSerializationFormats.html#BINARY"
      }}>{`type://ThriftSerializationFormats#BINARY`}</a>{`,
`}<a parentName="p" {...{
        "href": "type://ThriftSerializationFormats#COMPACT:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/common/thrift/ThriftSerializationFormats.html#COMPACT"
      }}>{`type://ThriftSerializationFormats#COMPACT`}</a>{`, `}<a parentName="p" {...{
        "href": "type://ThriftSerializationFormats#JSON:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/common/thrift/ThriftSerializationFormats.html#JSON"
      }}>{`type://ThriftSerializationFormats#JSON`}</a>{`
and `}<a parentName="p" {...{
        "href": "type://ThriftSerializationFormats#TEXT:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/common/thrift/ThriftSerializationFormats.html#TEXT"
      }}>{`type://ThriftSerializationFormats#TEXT`}</a>{`
using `}<a parentName="p" {...{
        "href": "type://ThriftClientBuilder#serializationFormat(SerializationFormat):https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/client/thrift/ThriftClientBuilder.html#serializationFormat(com.linecorp.armeria.common.SerializationFormat)"
      }}>{`type://ThriftClientBuilder#serializationFormat(SerializationFormat)`}</a>{`.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-java"
      }}>{`import com.linecorp.armeria.common.thrift.ThriftSerializationFormats;

HelloService.Iface helloService =
    ThriftClient.builder("http://127.0.0.1:8080")
                .path("/hello")
                .serializationFormat(ThriftSerializationFormats.JSON)
                .build(HelloService.Iface.class); // or AsyncIface.class
`}</code></pre>
    <p>{`Since we specified `}<inlineCode parentName="p">{`HelloService.Iface`}</inlineCode>{` as the client type, `}<inlineCode parentName="p">{`ThriftClients.newClient()`}</inlineCode>{` will return a
synchronous client implementation. If we specified `}<inlineCode parentName="p">{`HelloService.AsyncIface`}</inlineCode>{`, the calling code would have looked
like the following:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-java"
      }}>{`import com.linecorp.armeria.common.thrift.ThriftFuture;
import com.linecorp.armeria.common.util.CompletionActions;
import com.linecorp.armeria.client.thrift.ThriftClients;

HelloService.AsyncIface helloService = 
    ThriftClients.newClient("http://127.0.0.1:8080/hello",
                            HelloService.AsyncIface.class);

ThriftFuture<String> future = new ThriftFuture<String>();
helloService.hello("Armerian World", future);

future.thenAccept(response -> assert response.equals("Hello, Armerian World!"))
      .exceptionally(cause -> {
          cause.printStackTrace();
          return null;
      });

// You can also wait until the call is finished.
String reply = future.get();
`}</code></pre>
    <p>{`The example above introduces a new class called `}<a parentName="p" {...{
        "href": "type://ThriftFuture:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/common/thrift/ThriftFuture.html"
      }}>{`type://ThriftFuture`}</a>{`. It is a subtype of Java 8
CompletableFuture_ that implements Thrift `}<a parentName="p" {...{
        "href": "https://github.com/apache/thrift/blob/bd964c7f3460c308161cb6eb90583874a7d8d848/lib/java/src/org/apache/thrift/async/AsyncMethodCallback.java#L22"
      }}>{`AsyncMethodCallback`}</a>{`. Once passed as a callback of an asynchronous
Thrift call, `}<a parentName="p" {...{
        "href": "type://ThriftFuture:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/common/thrift/ThriftFuture.html"
      }}>{`type://ThriftFuture`}</a>{` will complete itself when the reply is received or the call
fails. You'll find it way more convenient to consume the reply than `}<a parentName="p" {...{
        "href": "https://github.com/apache/thrift/blob/bd964c7f3460c308161cb6eb90583874a7d8d848/lib/java/src/org/apache/thrift/async/AsyncMethodCallback.java#L22"
      }}>{`AsyncMethodCallback`}</a>{` thanks to the rich set
of methods provided by `}<a parentName="p" {...{
        "href": "https://docs.oracle.com/javase/10/docs/api/java/util/concurrent/CompletableFuture.html"
      }}>{`CompletableFuture`}</a>{`.`}</p>
    <p>{`You can also use the builder pattern for client construction:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-java"
      }}>{`import com.linecorp.armeria.common.HttpRequest;
import com.linecorp.armeria.common.HttpResponse;

HelloService.Iface helloService =
    ThriftClients.builder("http://127.0.0.1:8080")
                 .path("/hello")
                 .responseTimeoutMillis(10000)
                 .decorator(LoggingClient.newDecorator())
                 .build(HelloService.Iface.class); // or AsyncIface.class

String greeting = helloService.hello("Armerian World");
assert greeting.equals("Hello, Armerian World!");
`}</code></pre>
    <p>{`As you might have noticed already, we decorated the client using `}<a parentName="p" {...{
        "href": "type://LoggingClient:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/client/logging/LoggingClient.html"
      }}>{`type://LoggingClient`}</a>{`, which logs all
requests and responses. You might be interested in decorating a client using other decorators, for example
to gather metrics. Please also refer to `}<a parentName="p" {...{
        "href": "type://ThriftClientBuilder:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/client/thrift/ThriftClientBuilder.html"
      }}>{`type://ThriftClientBuilder`}</a>{` for more configuration options.`}</p>
    <h2 {...{
      "id": "see-also",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#see-also",
        "aria-label": "see also permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`See also`}</h2>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/docs/server-thrift"
        }}>{`Running a Thrift service`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/docs/client-decorator"
        }}>{`Decorating a client`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/docs/client-custom-http-headers"
        }}>{`Sending custom HTTP headers`}</a></li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      